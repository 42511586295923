import { Action } from 'redux';
import { RootState } from './rootReducer';
import { getInitialFormFieldsState } from 'src/components/forms/common/initialization';
import { clone, merge } from 'lodash';
import { getFieldsFromView } from 'src/mappers/common-mapper-functions';
import { validateForm, getFormGroupWithErrors, applyFieldRules } from 'src/components/forms/common/validation';
import { FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import { FormValuesType } from 'src/components/forms/form-elements/FormElement.model';
import { groupBy } from 'lodash';
import { FieldRule, FieldRuleType } from 'src/components/forms/form-elements/FormElement.model';
import { formatFieldValues, localToUTC } from 'src/utils/date-expression';

export enum modalFormsAction {
  INIT_MODAL_FORM = 'INIT_MODAL_FORM',
  SET_DEFAULT_MODAL_VALUES = 'SET_DEFAULT_MODAL_VALUES',
  RESET_MODAL_FORM = 'RESET_MODAL_FORM',
  VALIDATE_MODAL_FORM = 'VALIDATE_MODAL_FORM',
  // Migration
  SET_MODAL_FORM_EDIT = 'SET_MODAL_FORM_EDIT',
  ON_MODAL_FORM_CHANGE_FIELD = 'ON_MODAL_FORM_CHANGE_FIELD',
  SAVE_MODAL_FORM = 'SAVE_MODAL_FORM',
  MODAL_FORM_DISCARD_CHANGES = 'MODAL_FORM_DISCARD_CHANGES',
  MODAL_FORM_CLOSE = 'MODAL_FORM_CLOSE',
  RESET_MODAL_CHANGED_FIELD_FOR_FILED_RULES = 'RESET_MODAL_CHANGED_FIELD_FOR_FILED_RULES',
}
export interface modalForm {
  [x: string]: any;
  formValues: any;
  initErrors: { errors: { [key: string]: any }; valid: boolean };
  formErrors: { errors: { [key: string]: any }; valid: boolean };
  formFields: FormFieldsType | any;
  isEdit?: boolean;
  formGroups?: any;
  formChanges?: any;
  hasChanged?: boolean;
  _dbSavePending?: boolean;
  glideObject?: any;
}
export interface modalFormData {
  isEdit: boolean;
  initErrors: any;
  formErrors: any;
  hasChanged: boolean;
  formChanges: object;
  formGroups: { [key: string]: modalForm };
  initialFormGroup: object;
  // private state
  _dbSavePending: object;
  uri: any;
}
export interface modalFormAction extends Action {
  payload: any;
  type: modalFormsAction;
  fieldRules?: object;
  defaultValues?: object;
  mapFieldRulesFn?: (formdata: FormData, fieldRules: object) => FormData;
}

export interface ModalFormDiscardActionPayload {
  displayModalConfirmationDialog: Function;
  hideInspector: boolean;
  objectInView: string;
}

export interface modalFormState {
  uri: any;
}

const modalState: modalFormState = {
  uri: {},
};

export const getNextFormGroupState = ({
  formGroupsState,
  enableEdit,
}: {
  formGroupsState: { [key: string]: FormValuesType };
  enableEdit: boolean;
}) => {
  const nextFormGroupsState = Object.entries(formGroupsState).reduce(
    (formGroupsAcc, [formGroup, values]) => ({
      ...formGroupsAcc,
      [formGroup]: {
        ...formGroupsState[formGroup],
        formErrors: {},
        formFields: Object.entries(values.formFields).reduce(
          (formFieldsAcc: any, field: any) => ({
            ...formFieldsAcc,
            [field[0]]: {
              ...field[1],
              disabled: !enableEdit,
            },
          }),
          {},
        ),
        hasChanged: false,
      },
    }),
    {},
  );

  return nextFormGroupsState;
};

export const modalForm = (state: modalFormState = modalState, action: modalFormAction) => {
  switch (action.type) {
    case modalFormsAction.INIT_MODAL_FORM: {
      const newState = clone(state);
      const { glideObject } = action.payload;
      const clientViewUri = localStorage.getItem('last_view_selected');
      const uriInfo = glideObject?.displayView?.uri;
      const instanceUri = glideObject?.uri ? glideObject?.uri : clientViewUri;
      newState.uri[instanceUri] = {
        formValues: {},
        formErrors: { errors: {}, valid: true },
        formFields: {},
        isEdit: false,
        formGroups: {},
        formChanges: {},
        initErrors: { errors: {}, valid: true },
      };
      const fieldsObj: { [key: string]: any } = {};
      glideObject &&
        Object.entries(glideObject?.data).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, glideObject?.fieldRules, uriInfo, {}, key);
          merge(fieldsObj, fields);
        }, {});
      const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObj, isEditing: false });
      const fieldRules: any = [];
      glideObject &&
        glideObject?.fieldRules &&
        Object.entries(glideObject?.fieldRules).map((each: any) => {
          const [, value] = each;
          value.map((eachFieldRule: any) => {
            if (eachFieldRule.field_rule_run_on_open && eachFieldRule.enabled) fieldRules.push(eachFieldRule);
          });
        });
      const fieldRuleObject = applyFieldRules({
        fieldName: '',
        fieldRules: fieldRules,
        formValues: objectFormValues.formValues,
        isEdit: false,
        objectFormField: fieldsObj,
      });
      const final_fields = Object.keys(fieldRuleObject?.finalFields);
      Object.keys(fieldsObj).map((each: any) => {
        const eachWithField = 'fields/' + each;
        if (final_fields.includes(eachWithField)) {
          const fieldKey = fieldRuleObject?.finalFields[eachWithField];
          fieldsObj[each] = {
            ...fieldsObj[each],
            ...fieldKey,
            disabled: true,
          };
        }
      });

      const nextFormErrors = validateForm(
        objectFormValues.formValues,
        Object.keys(fieldsObj).length ? fieldsObj : newState?.uri[uriInfo]?.formFields,
      );
      merge(newState.uri, {
        [instanceUri as string]: {
          isEdit: false,
          hasChanged: false,
          formFields: fieldsObj,
          formValues: objectFormValues.formValues,
          initErrors: nextFormErrors,
          formErrors: {},
          isFormDirty: false,
          glideObject: glideObject,
        },
      });
      return newState;
    }
    case modalFormsAction.SAVE_MODAL_FORM: {
      const newState = clone(state);
      const { instanceUri } = action.payload;
      const clientViewUri = localStorage.getItem('last_view_selected');
      const objectUri = instanceUri ? instanceUri : clientViewUri;
      const nextFormErrors = validateForm(newState?.uri[objectUri]?.formValues, newState?.uri[objectUri].formFields);
      const hasErrors = Object.keys(nextFormErrors?.errors).length;
      if (hasErrors) {
        console.warn('form error, saving cancelled', nextFormErrors);
        merge(newState?.uri[objectUri], { initErrors: { errors: {} }, formErrors: nextFormErrors, isEdit: true });
      } else {
        Object.keys(newState?.uri[objectUri].formFields as any).map((key: any) => {
          if (
            newState?.uri[objectUri].formFields[key]?.format.includes('p') &&
            Object.keys(newState.uri[objectUri].formChanges).length &&
            Object.keys(newState.uri[objectUri].formChanges).includes(key)
          ) {
            newState.uri[objectUri].formChanges[key] = newState.uri[objectUri].formChanges[key] / 100;
          } else if (
            newState.uri[objectUri].formFields[key]?.fdddisplay &&
            Object.keys(newState.uri[objectUri].formChanges).length &&
            Object.keys(newState.uri[objectUri].formChanges).includes(key)
          ) {
            newState.uri[objectUri].formChanges[key] =
              newState.uri[objectUri].formChanges[key].replace(/,/g, '') /
              newState.uri[objectUri].formFields[key]?.fdddisplay;
          }
          if (
            newState.uri[objectUri].formFields[key]?.isScheduleUTC &&
            Object.keys(newState.uri[objectUri].formChanges).length &&
            Object.keys(newState.uri[objectUri].formChanges).includes(key)
          ) {
            newState.uri[objectUri].formChanges[key] = localToUTC(newState.uri[objectUri].formChanges[key]);
          }
          if (newState.uri[objectUri].formFields[key]?.isEdited) {
            newState.uri[objectUri].formFields[key].isEdited = false;
          }

          newState.uri[objectUri].formFields[key] = { ...newState.uri[objectUri].formFields[key], disabled: true };
        });
        merge(newState.uri, {
          [objectUri as string]: {
            isEdit: false,
            hasChanged: false,
            _dbSavePending: true,
          },
        });
      }
      return newState;
    }

    case modalFormsAction.SET_MODAL_FORM_EDIT: {
      const newState = clone(state);
      const { toggleState, glideObjects } = action.payload;
      const newEditState = toggleState !== undefined ? toggleState : true;
      const clientViewUri = localStorage.getItem('last_view_selected');
      const uriInfo = glideObjects?.displayView.uri;
      const objectUri = glideObjects?.uri ? glideObjects?.uri : clientViewUri;
      const glideObject = newState.uri[objectUri]?.glideObject;
      const fieldsObject: { [key: string]: any } = {};
      glideObject &&
        Object.entries(glideObject?.data).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, glideObject?.fieldRules, uriInfo, {}, key);
          merge(fieldsObject, fields);
        }, {});
      const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObject, isEditing: false });
      const fieldRules: any = [];
      glideObject?.fieldRules &&
        Object.entries(glideObject?.fieldRules).map((each: any) => {
          const [, value] = each;
          value.map((eachFieldRule: any) => {
            if (eachFieldRule.field_rule_run_on_open && eachFieldRule.enabled) fieldRules.push(eachFieldRule);
          });
        });
      const fieldRuleObject = applyFieldRules({
        fieldName: '',
        fieldRules: fieldRules,
        formValues: objectFormValues.formValues,
        isEdit: false,
        objectFormField: fieldsObject,
      });
      const fields = Object.keys(fieldRuleObject?.finalFields);
      const formattedValue = formatFieldValues(fieldRuleObject?.finalValues, objectFormValues?.formValues);
      const objectValues = Object.fromEntries(formattedValue);
      const values = Object.keys(fieldRuleObject?.finalValues);
      Object.keys(fieldsObject).map((each: any) => {
        const eachWithField = 'fields/' + each;
        if (fields.includes(eachWithField)) {
          const fieldKey = fieldRuleObject?.finalFields[eachWithField];
          fieldsObject[each] = {
            ...fieldsObject[each],
            ...fieldKey,
          };
        }
      });
      if (toggleState === true) {
        Object.keys(objectFormValues.formValues).map((key: any) => {
          if (values.includes(key) || values.includes(`fields/${key}`)) {
            objectFormValues.formValues[key] = fieldRuleObject?.finalValues[`fields/${key}`];
          }
        });
        //if (toggleState === true) {
        const nextFormErrors = validateForm(
          objectFormValues.formValues,
          Object.keys(fieldsObject).length ? fieldsObject : newState?.uri[uriInfo]?.formFields,
        );
        merge(newState.uri, {
          [objectUri as string]: {
            isEdit: false,
            hasChanged: false,
            formFields: fieldsObject,
            formValues: objectFormValues.formValues,
            formChanges: objectValues,
            initErrors: { errors: {} },
            formErrors: nextFormErrors,
            glideObject: glideObjects,
          },
        });
      }
      if (newEditState) {
        const nextFormGroupsState = getNextFormGroupState({
          formGroupsState: state.uri[objectUri]?.formGroups,
          enableEdit: newEditState,
        });

        Object.keys(newState?.uri[objectUri].formFields as any).map((x: any) => {
          newState.uri[objectUri].formFields[x] = { ...newState.uri[objectUri].formFields[x], disabled: !newEditState };
        });

        const formGroupWithErrors = getFormGroupWithErrors(nextFormGroupsState);
        merge(newState.uri[objectUri].formGroups, formGroupWithErrors);
        merge(newState.uri[objectUri].formFields, newState.uri[objectUri].formFields);
        merge(newState.uri[objectUri], { isEdit: true, hasChanged: false });
        newState.uri[objectUri].initErrors = { errors: {} };
        return newState;
      } else {
        Object.keys(newState?.uri[objectUri].formFields as any).map((key: any) => {
          newState.uri[objectUri].formFields[key] = { ...newState.uri[objectUri].formFields[key], disabled: true };
        });
        // Reset form
        const fieldGroups = groupBy(newState?.uri[objectUri].formFields, (field: any) => field.groupName);
        Object.entries(fieldGroups).map((acc: any) => {
          const [key, values] = acc;
          const fields = getFieldsFromView(values, {}, '', {}, key);
          merge(fieldsObject, fields);
        }, {});
        const objectFormValues = getInitialFormFieldsState({ formFields: fieldsObject, isEditing: false });

        merge(newState.uri, {
          [objectUri as string]: {
            formValues: objectFormValues.formValues,
            formFields: fieldsObject,
            formChanges: {},
            hasChanged: false,
            isEdit: false,
            formErrors: {},
            initErrors: { errors: {} },
          },
        });
        return newState;
      }
    }

    case modalFormsAction.ON_MODAL_FORM_CHANGE_FIELD: {
      const { fieldUpdate, objectInView } = action.payload;
      if (!state.uri[objectInView].isEdit) return state;
      const newState = clone(state);

      const updatedFormValues = { ...newState?.uri[objectInView].formValues, ...fieldUpdate };
      const fieldUpdated = Object.keys(action.payload.fieldUpdate)[0];
      let fieldUpdatedWithKey = 'fields/' + fieldUpdated;
      const childFieldToRerunFieldRules = [fieldUpdatedWithKey];
      let updatedValues: any = {};
      for (let childField = 0; childField < childFieldToRerunFieldRules.length; childField++) {
        fieldUpdatedWithKey = childFieldToRerunFieldRules[childField];

        Object.keys(newState?.uri[objectInView].formFields as any).map((formfieldKey: any) => {
          const fieldRules = newState?.uri[objectInView].formFields[formfieldKey]?.fieldRules;
          //Execute field rules on each field
          const fieldRulesToRun: FieldRule[] = [];
          // Include rules where the changed field is in the inputs,
          // but is not the target field unless the RuleType is Valid (a field can invalidate itself e.g.when checking pattern, format or length)
          // Sometimes its necessary to have the target field in the inputs where the rule needs the
          // previous value of that field, but if it changes it shouldn't trigger running the rule.
          if (fieldRules) {
            fieldRules.forEach((fieldRule: FieldRule) => {
              const inputFields = fieldRule?.field_rule_inputs;
              const targetFields = fieldRule?.field_rule_target
                ? [fieldRule?.field_rule_target]
                : fieldRule?.field_rule_targets;
              if (
                inputFields.some((inputItem: string) => inputItem === fieldUpdatedWithKey) &&
                (!targetFields.some((targetItem: string) => targetItem === fieldUpdatedWithKey) ||
                  fieldRule.ruleType === FieldRuleType.Valid)
              ) {
                fieldRulesToRun.push(fieldRule);
              }
            });
          }
          let fieldRuleObject: any;
          if (fieldRulesToRun.length > 0) {
            fieldRuleObject = applyFieldRules({
              fieldName: fieldUpdated,
              fieldRules: fieldRulesToRun,
              formValues: updatedFormValues,
              objectFormField: newState?.uri[objectInView].formFields,
              isEdit: true,
            });

            const fields = Object.keys(fieldRuleObject?.finalFields);
            const values = Object.keys(fieldRuleObject?.finalValues);
            const formattedValue = formatFieldValues(fieldRuleObject?.finalValues, updatedFormValues);
            const objectValues = Object.fromEntries(formattedValue);
            updatedValues = { ...updatedValues, ...objectValues };

            Object.keys(newState?.uri[objectInView].formFields).map((x: any) => {
              const eachXWithField = 'fields/' + x;
              if (fields.includes(eachXWithField)) {
                const fieldKey = Object.keys(fieldRuleObject?.finalFields[eachXWithField]);
                fieldKey?.map((eachKey: string) => {
                  newState.uri[objectInView].formFields[x] = {
                    ...newState?.uri[objectInView].formFields[x],
                    [eachKey]: fieldRuleObject?.finalFields[eachXWithField][eachKey],
                  };
                });
              }
            });

            Object.keys(updatedFormValues as any).map((x: any) => {
              const fieldName = 'fields/' + x;
              if (values.includes(fieldName) && updatedFormValues[x] !== fieldRuleObject?.finalValues[fieldName]) {
                updatedFormValues[x] = fieldRuleObject?.finalValues[fieldName];
                if (!childFieldToRerunFieldRules.some((item: any) => item === fieldName)) {
                  childFieldToRerunFieldRules.push(fieldName);
                }
              }
            });
          }
        });
      }

      //Validate each field
      newState.uri[objectInView].formErrors = validateForm(updatedFormValues, newState?.uri[objectInView].formFields);

      merge(newState.uri, {
        [objectInView as string]: {
          formValues: updatedFormValues,
          formFields: newState?.uri[objectInView].formFields,
          formChanges: {
            ...updatedValues,
            ...fieldUpdate,
          },
          fieldChangeForFieldRule: Object.keys({
            ...fieldUpdate,
          }),
          hasChanged: true,
          formErrors: newState?.uri[objectInView].formErrors,
          initErrors: { errors: {} },
        },
      });
      return newState;
    }

    case modalFormsAction.RESET_MODAL_FORM: {
      const { instanceUri } = action.payload;
      const clientViewUri = localStorage.getItem('last_view_selected');
      const uriInfo = instanceUri ? instanceUri : clientViewUri;
      if (state?.uri[uriInfo]) {
        delete state?.uri[uriInfo];
      }
      return {
        ...state,
      };
    }
    case modalFormsAction.RESET_MODAL_CHANGED_FIELD_FOR_FILED_RULES: {
      const { clientViewUri } = action.payload;
      const uriInfo = clientViewUri;
      if (state.uri[uriInfo]) {
        delete state.uri[uriInfo].fieldChangeForFieldRule;
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const setModalForm = (glideObject: object) => ({
  type: modalFormsAction.INIT_MODAL_FORM,
  payload: { glideObject },
});

export const saveModalForm = (instanceUri: string) => ({
  type: modalFormsAction.SAVE_MODAL_FORM,
  payload: { instanceUri },
});

export const changeModalFormField = (fieldUpdate: any, formGroupName: string, field?: any, objectInView?: string) => ({
  type: modalFormsAction.ON_MODAL_FORM_CHANGE_FIELD,
  payload: { fieldUpdate, formGroupName, field, objectInView },
});

export const setModalFormEdit = (toggleState: boolean, glideObjects: object) => ({
  type: modalFormsAction.SET_MODAL_FORM_EDIT,
  payload: { toggleState, glideObjects },
});

export const resetModalForm = (instanceUri: string) => ({
  type: modalFormsAction.RESET_MODAL_FORM,
  payload: { instanceUri },
});

export const discardModalFormChanges = (
  displayConfirmationDialog: Function,
  hideInspector: boolean,
  objectInView: string,
) => ({
  type: modalFormsAction.MODAL_FORM_DISCARD_CHANGES,
  payload: { displayModalConfirmationDialog: displayConfirmationDialog, hideInspector, objectInView },
});

export const closeModalForm = (hideInspector: boolean, objectInView: string) => ({
  type: modalFormsAction.MODAL_FORM_CLOSE,
  payload: { hideInspector, objectInView },
});

export const selectModalForm = (state: RootState): modalFormState => state.modalForm;

export const isModalFormEnabled = (state: RootState): boolean => {
  const modalInspector = state.components.modalInspector;
  return Object.values(modalInspector).some(value => value.isVisible === true);
};

export const ResetModalChangedFieldforFieldRules = (clientViewUri: string) => ({
  type: modalFormsAction.RESET_MODAL_CHANGED_FIELD_FOR_FILED_RULES,
  payload: { clientViewUri },
});
