import { Location as ReachLocation, LocationContext, Router } from '@reach/router';
import { appsLinks } from '@virtus/common/appLinks/appLinks';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import getEnvFromUrl from '@virtus/common/utils/getEnvFromUrl';
import VirtusTheme from '@virtus/components/VirtusTheme';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { AppLink, AppNames } from '@virtus/components/NavigationBar/components/AppMenu/AppMenu';
import { TopRightMenuItemProps } from '@virtus/components/NavigationBar/components/NavigationBarMenu/NavigationBarMenu';
import { Header } from '@virtus/components/page';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as S from 'src/app/App/App.style';
import AuthenticationHandlerGlide from 'src/app/App/auth-glide';
import { DbSelectorOverlay } from 'src/components/db-selector-overlay/db-selector-overlay';
import TabPanelNavigation from 'src/components/tab-panel-navigation/tab-panel-navigation';
import config, { GlideAppConfig } from 'src/config';
import configureHeader from 'src/config/headerConfig';
import { useEventListener } from 'src/hooks/useEventListener';
import { RootState } from 'src/reducers';
import { activeTabSelector, CustomGlideRoute, TabsAction, tabSelector, TabState } from 'src/reducers/tabs';
import GlideView from 'src/components/glide-view/glide-view';
import { GroupedList } from 'src/components/grouped-list/grouped-list';
import { globalActionSelector } from '../app.saga';
import { actions } from 'src/reducers/actions';
import { GlobalActionObject } from 'src/models/actions';
import './App.css';
import { backDropClick } from 'src/utils/constants';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { SearchIcon } from '@virtus/components/Search/Search.style';
import { dispatchActions } from '@virtus/glide/src/app/store';
import * as version from '@virtus/glide/version.json';
import { closeInspectorForm } from 'src/reducers/inspectorForm.reducer';
import { selectModalForm } from 'src/reducers/modalForm.reducer';
import ModalInspector from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import { fetchClientViewAction } from 'src/sagas/glide-view.saga';
import { default as devextremeConfig } from 'devextreme/core/config';
import { licenseKey } from '@virtus/glide/devextreme-license';
import { onInspectorRelease } from 'src/sagas/glide-object-handler.saga';
import { isLocked } from 'src/sagas/action/actions.saga';

devextremeConfig({ licenseKey });

interface ReduxProps {
  isLogout: boolean;
  Username: string;
  glideSession: GlideSession;
  tabs: TabState;
  globalActions: GlobalActionObject[];
  currentInspectorForm: any;
  currentModalForm: any;
  modalInspectorData: any;
  clientViewUri: string;
  isLocked: boolean;
}

interface ReduxDispatch {
  openNewTab: (path: string, routes?: any) => void;
  closeTab: (tab: CustomGlideRoute) => void;
  resolveAction: (action: any, targetUri?: string) => void;
  closeInspectorForm?: (hideInspector: boolean) => void;
  fetchClientView: (clientViewUri: string, isManualRefresh?: boolean) => void;
}

interface OwnProps {
  config: GlideAppConfig;
}

type AppProps = ReduxProps & OwnProps & ReduxDispatch;

interface TopRightMenuItems {
  validator: boolean;
  items: TopRightMenuItemProps;
}

const getTopRightMenuItems = (itemsGroup: TopRightMenuItems[]): TopRightMenuItemProps[] => {
  return itemsGroup
    .filter(itemGroup => itemGroup.validator)
    .map(group => {
      return group.items;
    });
};

// Needed to be able to target Glide website from other portal apps
window.name = 'glide';

/**
 * This is to disable alert message from Muse lib.
 * @Note :- This will also override any javascript alert message in entire Glide application
 */
window.alert = (msg: string) => console.warn(`[Muse] ${msg}`);

const appMenuLinks: AppLink[] = [
  {
    name: AppNames.nexus,
    // @ts-ignore
    route: 'https://nexus.virtusconnect.com',
    color: 'var(--fis-green)',
  },
  {
    name: AppNames.glide,
    // @ts-ignore
    route: appsLinks.glide[__ENVIRONMENT__],
    color: 'var(--default-blue)',
  },
  {
    name: AppNames.genesis,
    // @ts-ignore
    route: appsLinks.genesis[__ENVIRONMENT__],
    color: 'var(--purple-genesis)',
  },
];

/** Theme, Header and Routing */
export const App: React.FC<AppProps> = ({
  isLogout,
  Username,
  glideSession,
  openNewTab,
  closeTab,
  tabs,
  globalActions,
  isLocked,
  closeInspectorForm,
  resolveAction,
  currentInspectorForm,
  currentModalForm,
  modalInspectorData,
  clientViewUri,
  fetchClientView,
}) => {
  const [showDBDialog, setShowDBDialog] = useState(false);
  const [instantUriHandler, setInstantUriHandler] = useState('');

  useEffect(() => {
    fetchClientView(clientViewUri);
  }, [clientViewUri]);

  useEffect(() => {
    const handleonBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      onInspectorRelease();
    };
    if (isLocked) {
      window.addEventListener('beforeunload', handleonBeforeUnload);
    }
    return () => {
      if (isLocked) {
        window.removeEventListener('beforeunload', handleonBeforeUnload);
      }
    };
  }, [isLocked]);

  const uriHandler = (instanceUri: string) => {
    setInstantUriHandler(instanceUri);
  };
  const handleOnCloseDBDialog = (_event?: any, reason?: string) => {
    if (reason === backDropClick) return;
    setShowDBDialog(false);
  };

  const misc: JSX.Element = (
    <>
      <GroupedList
        data={globalActions}
        isInspectorFormDirty={currentInspectorForm?.isFormDirty}
        label="Global Actions"
        resolveAction={resolveAction}
        closeInspectorForm={closeInspectorForm}
        isModalInspector={currentModalForm?.isEdit}
        setInstanceUri={uriHandler}
        modalInspectorData={modalInspectorData}
      />
    </>
  );
  const toggleSearchInspector = () => dispatchActions.components.toggleDisplay('searchInspector');
  const extraIconButtons = [
    {
      Icon: SearchIcon,
      onClick: toggleSearchInspector,
      testId: 'search-button',
      tooltip: 'Global search',
    },
  ];

  const backHandler = () => {
    closeTab({
      fullPath: tabs.currentPath,
      isHighlighted: true,
      activeView: '',
    });
  };

  useEventListener('popstate', backHandler, window);

  const keyHandler = (event: KeyboardEvent) => {
    const winShortcut = event.altKey && event.key === '?';
    const osxShortcut = event.metaKey && event.key === '/';
    if (winShortcut || osxShortcut) return setShowDBDialog(true);
  };

  useEventListener('keydown', keyHandler);

  const clientSelector = useCallback(() => {
    if (getEnvFromUrl(['dev', 'local'], location.href) && glideSession) {
      return (
        <DbSelectorOverlay
          show={showDBDialog}
          onClose={handleOnCloseDBDialog}
          currentEnv={localStorage.getItem('client_env') || ''}
        />
      );
    }
    return null;
  }, [glideSession, showDBDialog]);

  const changeRoute = (path: string) => {
    openNewTab(path);
  };

  const getLoaderText = () => {
    const defaultText = 'Loading...';
    if (glideSession && Object.keys(glideSession).length && !isLogout) {
      return 'Initialising Glide Web...';
    } else if (isLogout) {
      return 'Signing out...Please wait while we redirect you to Login page';
    }
    return defaultText;
  };

  const loader = (
    <Loading
      type={LoadingIconType.Glide}
      size={LoadingIconSizes.extraLarge}
      text={getLoaderText()}
      show
      full
      style={{ height: '100vh' }}
    />
  );

  return (
    <Suspense fallback={loader}>
      <VirtusTheme>
        {(!glideSession?.user || isLogout) && loader}
        <AuthenticationHandlerGlide config={config} setClientEnv={setShowDBDialog}>
          {({ isGlideAuthenticated, login, logout }) => (
            <S.StyledApp>
              <ReachLocation>
                {(location: LocationContext) => {
                  const topRightMenuItems = getTopRightMenuItems([
                    {
                      validator: true,
                      items: {
                        text: 'Logout',
                        onClick: () => {
                          logout(config);
                        },
                      },
                    },
                  ]);
                  const headerConfiguration = configureHeader(tabs.routes);
                  return (
                    isGlideAuthenticated &&
                    tabs.routes &&
                    tabs.routes.length > 0 && (
                      <>
                        <Header
                          {...headerConfiguration}
                          appMenuLinks={appMenuLinks}
                          appMenuValue="Glide"
                          isLoggedIn={isGlideAuthenticated}
                          topRightButtonText={Username}
                          path={location.location.pathname}
                          topRightMenuItems={topRightMenuItems}
                          onLogin={login}
                          enabledApps={[AppNames.nexus, AppNames.glide]}
                          misc={misc}
                          extraIconButtons={extraIconButtons}
                          routeChangeCallback={changeRoute}
                          isHelpVisible={true}
                          helpURL={config.glide.baseURL + '/glide/help'}
                          currentPath={tabs.currentPath}
                          activeViewUri={tabs.activeViewUri}
                          isInspectorFormDirty={currentInspectorForm?.isFormDirty}
                        />
                        <TabPanelNavigation />
                        <Router>
                          {tabs.routes.map((route: any) => {
                            if (!route || !route.uri) return;
                            return (
                              <GlideView
                                key={route.uri}
                                path={route?.path}
                                default={route?.defaultRoute}
                                category={route?.category}
                                clientViewUri={clientViewUri}
                              />
                            );
                          })}
                        </Router>
                        <S.GlideFooter>
                          <S.GlideCopyRight>
                            &copy;{new Date().getFullYear()} FIS. All rights reserved.
                          </S.GlideCopyRight>
                          <S.GlideVersion>BSPM ver. {version.GlideVersion} </S.GlideVersion>
                        </S.GlideFooter>
                      </>
                    )
                  );
                }}
              </ReachLocation>
            </S.StyledApp>
          )}
        </AuthenticationHandlerGlide>
        {clientSelector()}
        <ModalInspector objectInView={instantUriHandler} key={React.useId()} />
      </VirtusTheme>
    </Suspense>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  isLogout: authSelectors.getStatus(state) === 'logout',
  Username: authSelectors.getUsername(state),
  glideSession: authSelectors.glideSession(state),
  tabs: tabSelector(state),
  globalActions: globalActionSelector(state),
  currentInspectorForm: selectCurrentForm(state),
  currentModalForm: selectModalForm(state),
  modalInspectorData: state.entities.modalInspector,
  clientViewUri: activeTabSelector(state),
  isLocked: isLocked(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  openNewTab: (path: string, routes: any) => dispatch({ type: TabsAction.OPEN_TAB, path, routes }),
  closeTab: (tab: CustomGlideRoute) => dispatch({ type: TabsAction.CLOSE_TAB, tab }),
  resolveAction: (action: any, targetUri?: string) =>
    dispatch({ type: actions.action.DISPATCH_ACTION, payload: { action, targetUri } }),
  closeInspectorForm: (hideInspector: boolean) => dispatch(closeInspectorForm(hideInspector)),
  fetchClientView: (clientViewUri, isManualRefresh) =>
    dispatch(fetchClientViewAction({ clientViewUri, isManualRefresh })),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
